import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";

const BASE_URL = "https://www.lasangha.family";

export const DefaultSeoComponent = () => {
  const { asPath } = useRouter();

  return (
    <DefaultSeo
      titleTemplate="%s | La Sangha"
      defaultTitle="La Sangha"
      description="La Sangha és un espai d’autocura i de salut holística integrativa familiar i un centre de teràpies naturals. Fem hatha i vinyasa  ioga, ioga per infants, ioga en família, pilates, contrologia, reflexologia, tècnica metamòrfica, reiki i gimnàstica hipopressiva postpart (hipopressius). Tallers de ioga, aeroioga, acroioga. Per a totes les etapes de la vida. Lloguer de sales per professionals."
      canonical={`${BASE_URL}${asPath}`}
      additionalMetaTags={[
        {
          property: "dc:creator",
          content: "Àlex Barba",
        },
        {
          name: "application-name",
          content: "La Sangha Website",
        },
      ]}
      additionalLinkTags={[
        {
          rel: "icon",
          href: `/favicon.ico`,
        },
        {
          rel: "apple-touch-icon",
          href: `/apple-touch-icon.png`,
          sizes: "180x180",
        },
      ]}
      openGraph={{
        type: "website",
        locale: "ca",
        url: `${BASE_URL}${asPath}`,
        site_name: "La Sangha",
        title: "La Sangha",
        description:
          "La Sangha és un espai d’autocura i de salut holística integrativa familiar i un centre de teràpies naturals. Fem hatha i vinyasa  ioga, ioga per infants, ioga en família, pilates, contrologia, reflexologia, tècnica metamòrfica, reiki i gimnàstica hipopressiva postpart (hipopressius). Tallers de ioga, aeroioga, acroioga. Per a totes les etapes de la vida. Lloguer de sales per professionals.",
      }}
    />
  );
};
