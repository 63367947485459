export const headerRoutes = [
  { title: "Inici", path: "" },
  { title: "Qui som?", path: "qui-som" },
  {
    title: "Què fem?",
    path: "que-fem",
  },
  { title: "Lloguer sales", path: "lloguer-sales" },
  { title: "Horaris i preus", path: "horaris-i-preus" },
  { title: "Contacte", path: "contacte" },
];
