import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { HeaderLink } from "../header-link";
import { FiMenu, FiArrowLeft } from "react-icons/fi";
import { menuListVariants } from "../../utils/variants";
import { headerRoutes } from "../../config/routes";

export const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();

  const handleBurgerClick = () => setIsMenuOpen((value) => !value);

  const handleGoBack = () => router.back();

  useEffect(() => {
    const closeMenu = () => setIsMenuOpen(false);
    router.events.on("routeChangeStart", closeMenu);

    return () => {
      router.events.off("routeChangeStart", closeMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="w-full flex flex-col items-center bg-white">
        <div className="fixed bg-white h-18 flex lg:flex-row lg:justify-between z-40 w-5/6 sm:w-4/5 lg:w-3/4 xl:w-2/3 2xl:w-[45%]">
        <div className="basis-1/3 flex justify-start items-center">
          {router.asPath !== "/" ? (
            <>
              <FiArrowLeft
                className="text-2xl text-gray cursor-pointer z-40 lg:hidden"
                onClick={handleGoBack}
              />
              <Link href="/" passHref={true}>
                <a className="relative w-10 h-10 cursor-pointer hidden lg:flex">
                  <Image
                    src="logo-lasangha.png"
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    priority
                    alt="logo"
                  />
                </a>
              </Link>
            </>
          ) : null}
        </div>
        <div className="basis-1/3 flex justify-center items-center lg:hidden">
          <Link href="/" passHref={true}>
            <a className="relative w-10 h-10 cursor-pointer">
              <Image
                src="logo-lasangha.png"
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                priority
                alt="logo"
              />
            </a>
          </Link>
        </div>
        <div className="basis-1/3 flex flex-row justify-end items-center">
          <>
            <FiMenu
              className={`${
                isMenuOpen
                  ? "-rotate-45 translate-x-20"
                  : "rotate-0 translate-x-0"
              } text-2xl text-gray cursor-pointer transition-all duration-300 ease-in-out z-50 lg:hidden`}
              onClick={handleBurgerClick}
            />
            <ul className="hidden lg:flex flex-row whitespace-nowrap gap-2">
              {headerRoutes.slice(1).map((route, index) => {
                return <HeaderLink key={index} {...route} isHeader />;
              })}
            </ul>
          </>
        </div>
        </div>
      </header>
      <div className={`${isMenuOpen ? "translate-x-0" : "translate-x-full"} flex flex-col items-center fixed transition-all duration-500 ease-in-out z-50 w-full z-50 inset-0 bg-blue overflow-hidden`}>
      <div
        className={`flex flex-col w-5/6 sm:w-4/5 lg:w-3/4 xl:w-2/3 2xl:w-[45%]`}
      >
        <div className="flex justify-end items-center h-18">
          <AiOutlineClose
            className={`${
              isMenuOpen
                ? "rotate-0 translate-x-0"
                : "rotate-45 -translate-x-100"
            } text-2xl text-white cursor-pointer transition-all duration-300 ease-in-out`}
            onClick={handleBurgerClick}
          />
        </div>
        <motion.nav initial={false} animate={isMenuOpen ? "open" : "closed"}>
          <motion.ul
            className="flex flex-col items-center justify-center w-full"
            variants={menuListVariants}
          >
            {headerRoutes.map((route, index) => {
              return <HeaderLink key={index} {...route} />;
            })}
          </motion.ul>
        </motion.nav>
        <div className="absolute top-2/4 -right-32 -rotate-90 text-7xl text-orange opacity-10 animate-pulse font-title">
          La Sangha
        </div>
      </div>
      </div>
    </>
  );
};
