import { FC } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { motion } from "framer-motion";
import { menuVariants } from "../../utils/variants";

type Props = {
  path: string;
  title: string;
  isHeader?: boolean;
};

export const HeaderLink: FC<Props> = ({ title, path, isHeader }) => {
  const { asPath } = useRouter();

  return (
    <motion.li
      className={`block p-2 cursor-pointer relative font-title uppercase ${
        asPath === `/${path}`
          ? "text-orange"
          : `${isHeader ? "text-blue" : "text-white"}`
      }`}
      variants={menuVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link href={`/${path}`} passHref>
        {title}
      </Link>
    </motion.li>
  );
};
